<template>
	<div class="menuList" @click="routerTo">
		<div class="icon"><slot name="icon"></slot></div>
		{{ label }}
	</div>
</template>


<script>
	export default {
		name: 'menuList',
		props: {
			label: {
				type: String,
				default: ''
			},
			linkUrl: {
				type: String,
				default: '/'
			},
			linkOutUrl: {
				type: String,
				default: '/'
			},
			queryParams: {
				style: Object,
				default: () => {}
			},
			linkName: {
				type: String,
				default: '/'
			},
			params: {
				style: Object,
				default: () => {}
			},
		},
		methods: {
			routerTo() {
				if (this.linkUrl && this.linkUrl !== '/') {
				    this.$router.push({
						path:this.linkUrl,
						query: this.queryParams,
					});
					return
				}
				if (this.linkOutUrl && this.linkOutUrl !== '/') {
					window.open(this.linkOutUrl)//, '_self'
					return
				}
				if (this.linkName && this.linkName !== '') {
				    this.$router.push({
						name:this.linkName,
						params:this.params,
					});
					return
				}
			}
		}
	}
</script>

<style scoped lang="less">
	.menuList {
		font-family: PingFang SC;
		width: 25%;
		color: #666666;
		font-size: 0.24rem;
		transform: scale(0.916);
		text-align: center;
		margin-bottom: 0.15rem;
		text-transform: capitalize;
		.icon {
			width: 0.8735rem;
			height: 0.8735rem;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				display: block;
				width: 100%;
			}
		}
	}
</style>
